import * as React from "react";
import { 
  Create, 
  Edit, 
  SimpleForm, 
  List, 
  TextInput,
  useTranslate,
  Datagrid,
  Filter,
  TextField, 
  SelectInput,
  EditButton,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

import CountUserLinked from './clientCountUserLinked';
import CountBotLinked from './clientCountBotLinked';
import DisplayUserBotLinked from './clientDisplayUserBotLinked';
import StripeCustomerLink from './clientStripeCustomerLink';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const businessSector = [
  { id: '----', name: '----' },
  { id: 'e-commerce', name: 'e-commerce' },
  { id: 'assurance', name: 'assurance' },
  { id: 'medias', name: 'medias' },
  { id: 'immobilier', name: 'immobilier' },
  { id: 'services', name: 'services' },
  { id: 'banque', name: 'banque' },
  { id: 'administration', name: 'administration' },
  { id: 'automobile', name: 'automobile' },
  { id: 'food', name: 'food' },
  { id: 'fashion', name: 'fashion' },
  { id: 'energie', name: 'energie' },
  { id: 'travel', name: 'travel' },
  { id: 'construction', name: 'construction' },
  { id: 'other', name: 'other' },
];

const ClientFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ClientList = props => {
  return (
      <List {...props} perPage={25} filters={<ClientFilter />} >
          <Datagrid rowClick="expand" expand={<DisplayUserBotLinked />}>
             <TextField source="id" />
             <TextField source="name" label="🏢 Name"/>
             <TextField source="email" />
             <CountUserLinked label="👩‍💻 Users linked" />
             <CountBotLinked label="🤖 Bots linked" />
             <TextField source="business_sector" />
             <StripeCustomerLink label="💰 Stripe customer ID" />
            <EditButton basePath="/client" label="Edit" record={props} />
         </Datagrid>
      </List>
  );
};

export const ClientCreate = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm>
          <SectionTitle label="Organization general informations" />
          <TextInput source="name" formClassName={classes.first_name} fullWidth/>
          <TextInput source="email" type="email" validation={{ email: true }} fullWidth/>
          <TextInput source="website_url" fullWidth />
          <SelectInput source="business_sector" choices={businessSector} />
          <TextInput source="client_id" defaultValue={uuidv4()} fullWidth/>
      </SimpleForm>
  </Create>
  );
};

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
      <Typography variant="h6" gutterBottom>
          {translate(label)}
      </Typography>
  );
};

export const ClientEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput disabled label="id" source="id" />
      <SectionTitle label="Informations" />
      <TextInput source="name" fullWidth/>
      <TextInput source="email" fullWidth/>
      <TextInput source="website_url" fullWidth/>
      <SelectInput source="business_sector" choices={businessSector} />
      <TextInput disabled source="client_id" fullWidth />
    </SimpleForm>
  </Edit>
);
