import * as React from "react";
import { 
  Create, 
  Edit, 
  SimpleForm, 
  List, 
  TextInput,
  useTranslate,
  Datagrid, 
  TextField, 
  AutocompleteInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  EditButton
} from 'react-admin';
import { Typography } from '@material-ui/core';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const ClientUserPagination = props => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;

export const ClientUserList = props => {
  return (
      <List {...props} perPage={100} pagination={<ClientUserPagination />}>
          <Datagrid>
            <TextField source="id" />
            <ReferenceField label="User" reference="user_ext" source="user_id">
              <TextField source="email" />
            </ReferenceField>
            <ReferenceField label="Client" source="client_id" reference="client">
              <TextField source="name" />
            </ReferenceField>
            <EditButton basePath="/clientuser" label="Edit" record={props} />
         </Datagrid>
      </List>
  );
};

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
      <Typography variant="h6" gutterBottom>
          {translate(label)}
      </Typography>
  );
};


export const ClientUserCreate = (props) => {
  return (
    <Create {...props}>
    <SimpleForm redirect="list">
      <SectionTitle label="User :" />
      <ReferenceInput label="Userext" source="user_id" perPage={1000} reference="user_ext" filterToQuery={searchText => ({ email: [searchText] })}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <SectionTitle label="Client :" />
      <ReferenceInput label="Client" source="client_id" perPage={2000} reference="client" filterToQuery={searchText => ({ q: [searchText] })}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
  );
};

export const ClientUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput disabled label="id" source="id" />
      <SectionTitle label="User :" />
      <ReferenceInput label="User" source="user_id" reference="user_ext" filterToQuery={searchText => ({ email: [searchText] })}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <SectionTitle label="Client :" />
      <ReferenceInput label="Client" source="client_id" reference="client" filterToQuery={searchText => ({ q: [searchText] })}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
