import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import simpleRestProvider from 'ra-data-simple-rest';

import { UserList, UserEdit } from './ressources/users';
import { UserextEdit } from './ressources/usersAccount';
import { UserClientEdit } from './ressources/userClient';
import { ClientAccountList } from './ressources/clientsAccount';
import { BotAccountList } from './ressources/botsAccount';
import { ClientUserList, ClientUserCreate, ClientUserEdit } from './ressources/linkClientUser';
import { UserbotList, UserbotCreate, UserbotEdit } from './ressources/linkUserBot';
import { BillinginfoList, BillinginfoCreate, BillinginfoEdit } from './ressources/billinginfo';
import { ClientList, ClientCreate, ClientEdit } from './ressources/clients';
import { BotList, BotEdit } from './ressources/bots';
import { ConvCountList } from './ressources/conversationsCount';
import CustomLoginPage from './pages/CustomLoginPage';


import 'typeface-roboto';

const config = {
  apiKey: "AIzaSyBGMIzsrSz5jW9W58czal0Af9qjvWd5Fgs",
  authDomain: "tolk-copilot.firebaseapp.com",
  projectId: "tolk-copilot",
  storageBucket: "tolk-copilot.appspot.com",
  messagingSenderId: "274862805593",
  appId: "1:274862805593:web:6e4ed610c8d99123c9d748"
};
const options = {
  watch: [],
  dontwatch: ['user', 'userclient']
}

const useStyles = makeStyles((theme) => ({
  admin: {
    flexGrow: 1,
    margin: '0px',
    backgroundColor: 'white',
  },
  body: {
    margin: 0,
  }
}));

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-Custom-Header', 'foobar');
  options.headers.set('Access-Control-Expose-Headers', 'Content-Range, X-Total-Count');
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(window._env_.COPILOT_API_URL, httpClient, 'X-Total-Count');
const authProvider = FirebaseAuthProvider(config, options);
const App = () => {
  const classes = useStyles();
  return (
  <Admin loginPage={CustomLoginPage} dataProvider={dataProvider} authProvider={authProvider} className={classes.admin}>
    <Resource name="user" options={{ label: '🤗 Users' }} list={UserList} edit={UserEdit} />
    <Resource name="userclient" options={{ label: '🤗 Users Client' }} edit={UserClientEdit} />
    <Resource name="user_ext" options={{ label: '🤗 Users ext' }} edit={UserextEdit} />
    <Resource name="clientuser" options={{ label: '🔗 Clients < > Users' }} list={ClientUserList} edit={ClientUserEdit} create={ClientUserCreate}/>
    <Resource name="userbot" options={{ label: '🔗 Users < > Bots' }} list={UserbotList} edit={UserbotEdit} create={UserbotCreate}/>
    <Resource name="client" options={{ label: '🏢 Clients' }} list={ClientList} create={ClientCreate} edit={ClientEdit} />
    <Resource name="clientaccount" options={{ label: '🏢 Clients (read only)' }} list={ClientAccountList} />
    <Resource name="conversations_count" options={{ label: '📊 Convs' }} list={ConvCountList} />
    <Resource name="client_billing_info" options={{ label: '💰 Billing Infos' }} list={BillinginfoList} create={BillinginfoCreate} edit={BillinginfoEdit} />
    <Resource name="bots" options={{ label: '🤖 Bots' }} list={BotList} edit={BotEdit}/>
    <Resource name="botaccount" options={{ label: '🤖 Bots (read only)' }} list={BotAccountList} />
  </Admin>
  );
};

export default App;