import * as React from 'react';
import { useQuery, Link, linkToRecord } from 'react-admin';

const ClientName = ({ record }) => {
  const { data } = useQuery({ 
      type: 'getOne',
      resource: 'userclient',
      payload: { id: record.user_id }
  });
  if (!data) return null;
  const userShowPage = linkToRecord('/client', data.id, 'show');
  return (
    <Link to={userShowPage}>{data.name}</Link>
  )
};

ClientName.defaultProps = {
    label: '🏢 Client',
    textAlign: 'left',
};

export default ClientName;