import * as React from "react";
import { 
  List, 
  Datagrid,
  ReferenceField,
  TextField
} from 'react-admin';

export const BotAccountList = props => {
  return (
    <List {...props} perPage={100} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" label="Bot ID"/>
        <ReferenceField label="Bot" source="bot_id" reference="bots">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Client" reference="client" source="client_id">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
