import * as React from 'react';
import { useGetManyReference, Loading } from 'react-admin';

const CountUserLinked = ({ record }) => {
  const { data, ids, loading, error } = useGetManyReference(
    'clientuser',
    'client_id',
    record.id,
    { page: 1, perPage: 100 },
    { field: 'client_id', order: 'DESC' },
    {},
    'clients',
  );

  if (!data) return null;
  if (loading) { return <Loading />; }
  if (error) { return <p>ERROR</p>; }
  return <span>{ids.length}</span>;
};

CountUserLinked.defaultProps = {
    textAlign: 'left',
};

export default CountUserLinked;