import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { List, 
  Datagrid,
  Pagination, 
  TextInput, 
  Filter,
  BooleanInput, 
  Edit, 
  ListContextProvider,
  SimpleForm,
  Toolbar,
  SaveButton,
  SelectInput,
  TextField,
  EditButton,
  useListContext,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  useGetList,
  ReferenceInput,
  ReferenceField
} from 'react-admin';
import { Divider, Tabs, Tab } from '@material-ui/core';

const tabs = [
  { id: 'activated', name: '🔥 Activated' },
  { id: 'archived', name: '🧊 Archived' },
];

const useGetTotals = (filterValues) => {
  const { total: totalActivated } = useGetList(
      'bots',
      { perPage: 1, page: 1 },
      { field: 'id', order: 'ASC' },
      { ...filterValues, status: 'activated' }
  );
  const { total: totalArchived } = useGetList(
      'bots',
      { perPage: 1, page: 1 },
      { field: 'id', order: 'ASC' },
      { ...filterValues, status: 'archived' }
  );

  return {
      activated: totalActivated,
      archived: totalArchived,
  };
};

const TabbedDatagrid = (props) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [activated, setActivated] = useState();
  const [archived, setArchived] = useState();
  const totals = useGetTotals(filterValues);
  useEffect(() => {
      if (ids && ids !== filterValues.status) {
          switch (filterValues.status) {
              case 'activated':
                setActivated({
                  ...activated,
                  ids
                });
                  break;
              case 'archived':
                setArchived({
                  ...archived,
                  ids
                });
                  break;
              default:
                setActivated({
                  ...activated,
                  ids
                });
                  break;
          }
      }
  }, [activated, archived, ids, filterValues.status]);

  const handleChange = useCallback(
      (event, value) => {
          setFilters &&
              setFilters(
                  { ...filterValues, status: value },
                  displayedFilters
              );
      },
      [displayedFilters, filterValues, setFilters]
  );

  return (
      <Fragment>
          <Tabs
              variant="fullWidth"
              centered
              value={filterValues.status}
              indicatorColor="primary"
              onChange={handleChange}
          >
              {tabs.map(choice => (
                  <Tab
                      key={choice.id}
                      label={
                        totals[choice.name]
                            ? `${choice.name} (${totals[choice.name]})`
                            : choice.name
                    }
                      value={choice.id}
                  />
              ))}
          </Tabs>
          <Divider />
            <div>
              <ListContextProvider value={{ ...listContext }}>
                <Datagrid {...props} optimized rowClick="edit">
                  <TextField source="bot_id" label="🤖 Bot ID" />
                  <TextField source="name" />
                  <ReferenceField label="Client" source="client_id" reference="client">
                      <TextField source="name" />
                  </ReferenceField>
                  <ChipField source="gladosInstance" color="primary" />
                  <ReferenceArrayField label="Users" reference="user_ext" source="user_ids">
                      <SingleFieldList>
                          <ChipField source="email" />
                      </SingleFieldList>
                  </ReferenceArrayField>
                  <EditButton basePath="/bots" label="Edit" record={props} /> 
                </Datagrid>
              </ListContextProvider>
            </div>
      </Fragment>
  );
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]} {...props} />;
const PostFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput source="gladosInstance" choices={[
          { id: 'glados', name: 'glados (01)' },
          { id: 'glados02', name: 'glados 02' },
          { id: 'glados03', name: 'glados 03' },
      ]} />
  </Filter>
);
const PostEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);
export const BotList = props => (
    <List {...props} perPage={25} title="List of bots" filterDefaultValues={{ status: 'activated' }} filters={<PostFilter />} pagination={<PostPagination />} bulkActionButtons={false}>
        <TabbedDatagrid />
    </List>
);

export const BotEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextField source="bot_id" label="🤖 Bot ID" />
      <TextInput source="name" fullWidth/>
      <ReferenceInput label="Client" source="client_id" reference="client" perPage="200">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="archived" />
    </SimpleForm>
  </Edit>
);