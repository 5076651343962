import * as React from "react";
import { 
  Edit, 
  SimpleForm, 
  List, 
  TextInput,
  useTranslate,
  Datagrid, 
  TextField, 
  Filter,
  Pagination,
  EditButton
} from 'react-admin';
import { Typography } from '@material-ui/core';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

// const userRole = [
//   { id: 'tolk-admin', name: 'tolk-admin' },
//   { id: 'dev', name: 'dev' },
// ];

const UserPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />;

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserClientList = props => {
  return (
      <List {...props} perPage={50} filters={<UserFilter />} pagination={<UserPagination />} bulkActionButtons={false}>
          <Datagrid>
             <TextField source="user_id" label="👩‍💼 User ID" />
             <TextField source="client_id" label="🏢 Client ID" />
            <EditButton basePath="/userclient" label="Edit" record={props} />
         </Datagrid>
      </List>
  );
};

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
      <Typography variant="h6" gutterBottom>
          {translate(label)}
      </Typography>
  );
};

export const UserClientEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <SectionTitle label="Informations" />
      <TextInput disabled source="user_id" />
      <TextInput disabled source="client_id" />
    </SimpleForm>
  </Edit>
);
