import * as React from 'react';

import { 
  Loading, 
  useGetManyReference, 
  useQuery,
  CreateButton
} from 'react-admin';

const DisplayUserEmail = ({ user_id }) => {
  const { data, loading } = useQuery({ 
    type: 'getOne',
    resource: 'user_ext',
    payload: { id: user_id }
  });
  if (loading) return <Loading />;
  if (!data) return null;
  return <li>{data.email}</li>
};

const DisplayBotName = ({ bot_id }) => {
  const { data, loading } = useQuery({ 
    type: 'getOne',
    resource: 'bots',
    payload: { id: bot_id }
  });
  if (loading) return <Loading />;
  if (!data) return null;
  return <li>{data.name}</li>
};

const ClientUserCreateButton = (client_id) => (
  <CreateButton
        label="Create Link between Client and User"
        to={{
            pathname: '/clientuser/create',
            search: `?source=${JSON.stringify(client_id)}`,
        }}
    >
        Create Link between User and Bot
    </CreateButton>
);

const BotsList = ({ record }) => {
  const { data, ids, botLoading, botError } = useGetManyReference(
    'botaccount',
    'client_id',
    record.id,
    { page: 1, perPage: 100 },
    { field: 'bot_id', order: 'DESC' },
    {},
    'bots',
  );

  if (botLoading) { return <Loading />; }
  if (botError) { return <p>ERROR</p>; }

  if(ids.length > 0){
    return (
      <div>
        <h3>Bots(s) associé(s) à ce client : </h3>
        <ul>
            {ids.map(id =>
                <DisplayBotName bot_id={data[id].bot_id} />
            )}
        </ul>
      </div>
  );
  }else{
    return (
      <div>
        <h3>Bots(s) associé(s) à ce client : </h3>
        <ul>
          <li>Aucun bot associé à cet utilisateur</li>
        </ul>
    </div>
  );
  }
};

const UsersList = ({ record }) => {
    const { data, ids, loading, error } = useGetManyReference(
      'clientuser',
      'client_id',
      record.id,
      { page: 1, perPage: 100 },
      { field: 'user_id', order: 'DESC' },
      {},
      'users',
    );

    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    if(ids.length > 0){
      return (
        <div>
          <h3>Users(s) associé(s) à ce client : </h3>
          <ul>
              {ids.map(id =>
                  <DisplayUserEmail user_id={data[id].user_id} />
              )}
          </ul>
          <ClientUserCreateButton client_id={record.id}/>
        </div>
    );
    }else{
      return (
        <div>
          <ul>
            <li>Aucun bot associé à cet utilisateur</li>
          </ul>
          <ClientUserCreateButton client_id={record.id}/>
      </div>
    );
    }
};

const UsersBotsList = ({ record }) => {
    return (
      <div>
        <UsersList record={ record }/>
        <BotsList record={ record }/>
      </div>
  );
};

export default UsersBotsList;
