import * as React from 'react';
import { useGetManyReference, Loading } from 'react-admin';

const CountBotLinked = ({ record }) => {
  const { data, ids, loading, error } = useGetManyReference(
    'userbot',
    'user_id',
    record.user_id,
    { page: 1, perPage: 100 },
    { field: 'user_id', order: 'DESC' },
    {},
    'bots',
  );

  if (!data) return null;
  if (loading) { return <Loading />; }
  if (error) { return <p>ERROR</p>; }
  return <span>{ids.length}</span>;
};

CountBotLinked.defaultProps = {
    textAlign: 'left',
};

export default CountBotLinked;