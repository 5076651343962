import * as React from "react";
import { 
  List, 
  TextInput,
  Datagrid,
  Filter,
  TextField
} from 'react-admin';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const ClientFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ClientAccountList = props => {
  return (
      <List {...props} perPage={1000} filters={<ClientFilter />} bulkActionButtons={false}>
          <Datagrid>
             <TextField source="id" label="🏷 Client ID"/>
             <TextField source="name" label="🏢 Name"/>
             <TextField source="email" />
         </Datagrid>
      </List>
  );
};
