import * as React from 'react';
import { useGetManyReference, Loading } from 'react-admin';

const DisplayStripeCus = ({ cus_id }) => {
  const url = `https://dashboard.stripe.com/customers/${cus_id}`;
  return <a target="_blank" href={url} rel="noopener noreferrer">{cus_id}</a>
};

const StripeCustomerLink = ({ record }) => {
  const { data, ids, loading } = useGetManyReference(
    'client_billing_info',
    'client_id',
    record.id,
    { page: 1, perPage: 100 },
    { field: 'client_id', order: 'DESC' },
    {},
    'client',
  );

  if (loading) return <Loading />;
  if (!data) return null;

  if (ids.length > 0) {
    return (
      <span>
        {ids.map(id =>
          <DisplayStripeCus cus_id={data[id].billing_id} />
        )}
      </span>
    );
  } else {
    return (
      <span>⚠️ Not linked with Stripe</span>
    );
  }
};

StripeCustomerLink.defaultProps = {
    textAlign: 'left',
};

export default StripeCustomerLink;