import * as React from "react";
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField,
  AutocompleteInput,
  DateInput,
  ReferenceInput
} from 'react-admin';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const listFilters = [
  <DateInput source="date_gte" alwaysOn />,
  <DateInput source="date_lte" alwaysOn />,
  <ReferenceInput source="client_id" reference="clientaccount" filterToQuery={searchText => ({ q: [searchText] })} alwaysOn>
    <AutocompleteInput optionText="name" optionValue="id" />
  </ReferenceInput>
];


export const ConvCountList = props => {
  return (
    <List {...props} perPage={50} filters={listFilters} bulkActionButtons={false}>
      <Datagrid rowClick="expand">
        <ReferenceField label="🏢 Client" source="clientId" reference="clientaccount">
          <TextField source="name" optionValue="client_id" />
        </ReferenceField>
        <TextField label="📈 Number of conversations in this perdiod" source="count"  />
        <TextField label="🏷 Client ID" source="id" />
      </Datagrid>
    </List>
  );
};


