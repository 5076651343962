import * as React from "react";
import { 
  Create, 
  Edit, 
  SimpleForm, 
  List, 
  TextInput,
  useTranslate,
  Datagrid, 
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  EditButton
} from 'react-admin';
import { Typography } from '@material-ui/core';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

export const BillinginfoList = props => {
  return (
      <List {...props} perPage={200}>
          <Datagrid>
             <TextField source="id" />
             <ReferenceField label="Client" source="client_id" reference="client">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="billing_id" />
            <EditButton basePath="/client_billing_info" label="Edit" record={props} />
         </Datagrid>
      </List>
  );
};

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
      <Typography variant="h6" gutterBottom>
          {translate(label)}
      </Typography>
  );
};
export const BillinginfoCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
          <SectionTitle label="Client" />
          <ReferenceInput label="Client" source="client_id" reference="client" perPage="200">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SectionTitle label="Stripe customer ID" />
          <TextInput source="billing_id" />
      </SimpleForm>
  </Create>
  );
};

export const BillinginfoEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="id" source="id" />
      <ReferenceInput label="Client" source="client_id" reference="client">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="billing_id" />
    </SimpleForm>
  </Edit>
);
