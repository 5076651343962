// LoginPage.js
import React from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '#/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

const useStyles = makeStyles((theme) => ({
  login: {
    margin: '-8px',
  }
}));

const CustomLoginPage = props => {
  const classes = useStyles();
  return (
    <Login {...props} backgroundImage="https://source.unsplash.com/IDpcNA__GEk/1600x900" className={classes.login}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Login>
  )
}

export default CustomLoginPage;