import * as React from 'react';

import { 
  Loading, 
  useGetManyReference, 
  useQuery,
  CreateButton
} from 'react-admin';

const DisplayBotName = ({ bot_id }) => {
  const { data, loading } = useQuery({ 
    type: 'getOne',
    resource: 'bots',
    payload: { id: bot_id }
  });
  if (loading) return <Loading />;
  if (!data) return null;
  return <li>{data.name} ({data.bot_id})</li>
};

const CommentCreateButton = (user_id) => (
  <CreateButton
        label="Create Link between User and Bot"
        to={{
            pathname: '/userbot/create',
            search: `?source=${JSON.stringify(user_id)}`,
        }}
    >
        Create Link between User and Bot
    </CreateButton>
);

const UserShow = ({ record }) => {
    const { data, ids, loading, error } = useGetManyReference(
        'userbot',
        'user_id',
        record.user_id,
        { page: 1, perPage: 100 },
        { field: 'user_id', order: 'DESC' },
        {},
        'bots',
    );

    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    if(ids.length > 0){
      return (
        <div>
          <h3>Bot(s) associé(s) à cet utilisateur : </h3>
          <ul>
              {ids.map(id =>
                  <DisplayBotName bot_id={data[id].bot_id} />
              )}
          </ul>
          <CommentCreateButton user_id={record.user_id}/>
        </div>
    );
    }else{
      return (
        <div>
          <ul>
            <li>Aucun bot associé à cet utilisateur</li>
          </ul>
          <CommentCreateButton user_id={record.user_id}/>
      </div>
    );
    }
};

export default UserShow;
